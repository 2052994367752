import React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { Container } from '../components/Layout/Container';
import { AboutPageData, ImageWithSize } from '../types';
import { ImageBox } from '../components/ImageBox';
import { ResponsiveCloudinaryImage } from '../components/ResponsiveCloudinaryImage';
import { aboveMobile } from '../services/mediaquery';
import { Title } from '../components/Layout/Title';
import { Markdown } from '../components/Layout/Markdown';

const Root = styled(Container)``;

const AboutContent = styled(Markdown)`
    ${aboveMobile} {
        margin-top: 30px;
        width: 623px;
    }
`;

interface AboutPageProps {
    data: {
        site: {
            siteMetadata: {
                aboutPage: AboutPageData<ImageWithSize>;
            };
        };
    };
}

function AboutPage(props: AboutPageProps) {
    const {
        data: {
            site: {
                siteMetadata: { aboutPage },
            },
        },
    } = props;

    return (
        <Root>
            <Title>{aboutPage.title}</Title>
            <ImageBox>
                <ResponsiveCloudinaryImage
                    image={aboutPage.image.medium}
                    getOptions={(size) => ({
                        maxHeight: (size?.height ?? 0) * 0.5,
                        maxWidth: (size?.width ?? 0) * 0.8,
                        quality: 90,
                        crop: 'fill',
                    })}
                />
            </ImageBox>
            <AboutContent>{aboutPage.content}</AboutContent>
        </Root>
    );
}

export const query = graphql`
    query QueryAboutPage {
        site {
            siteMetadata {
                aboutPage {
                    title
                    image {
                        original {
                            url
                            width
                            height
                        }
                        medium {
                            url
                            width
                            height
                        }
                        large {
                            height
                            url
                            width
                        }
                    }
                    content
                }
            }
        }
    }
`;

export default AboutPage;
